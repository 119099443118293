/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-19 14:58:02
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-26 01:56:11
 */
import HandleData from '@/service/Custom'
export default {
  name: 'CustomReject',
  data () {
    return {
      WCustomRejectShow: false,
      form: {
        reason: '',
        id: ''
      },
      rules: {
        reason: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写驳回理由')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }]
      }
    }
  },
  methods: {
    show () {
      this.WCustomRejectShow = true
    },
    hide () {
      this.$set(this.form, 'reason', '')
      this.WCustomRejectShow = false
    },
    submit () {
      this.$refs.form.validate(async v => {
        if (v) {
          await this.$set(this.form, 'id', this.$parent.form.custom_id)
          const payload = new HandleData(this.form).adminReject()
          const data = await this.$wPost('/admin/custom/update_status.do', payload)
          if (data === true) {
            this.$wToast.success('驳回成功')
            this.$router.replace({ path: '/admin/custom' })
          }
          this.$refs.submitButton.updatedLoading(false)
        } else {
          this.$refs.submitButton.updatedLoading(false)
        }
      })
    }
  }
}
