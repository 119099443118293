/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-19 14:09:37
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-26 01:56:04
 */
import HandleData from '@/service/Custom'
import { cloneDeep } from 'lodash'
export default {
  name: 'CustomBind',
  data () {
    return {
      WCustomBindShow: false,
      options: [],
      form: {
        user_list: []
      }
    }
  },
  methods: {
    async getUserList (keyword) {
      const data = await this.$wPost('/admin/custom/list_user.do', {
        keyword
      })
      this.options = data
    },
    leaderToggle (res) {
      console.log(res)
      this.form.user_list = res
    },
    async submit () {
      console.log(this.$parent.form)
      await this.$set(this.form, 'custom_id', this.$parent.form.custom_id)
      const payload = new HandleData(this.form).adminCombindUser()
      console.log(payload)
      const data = await this.$wPost('/admin/custom/update_user.do', payload)
      if (data === true) {
        this.$wToast.success('绑定成功')
        this.hide()
        this.$parent.init()
      }
      this.$refs.submitButton.updatedLoading(false)
    },
    show () {
      this.$set(this.form, 'user_list', cloneDeep(this.$parent.form.user_list))
      this.WCustomBindShow = true
    },
    hide () {
      this.$set(this.form, 'user_list', cloneDeep([]))
      this.WCustomBindShow = false
    }
  }
}
