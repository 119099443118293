/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-19 14:09:42
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-19 14:16:28
 */
import CustomBind from './CustomBind.vue'
export default CustomBind
