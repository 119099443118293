/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-19 14:58:07
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-19 15:29:18
 */
import CustomReject from './CustomReject.vue'
export default CustomReject
